export const style = {
  inpuStyle: {
    colorInput: '#2E63FB'
  },
  url: {
    urlClient: 'https://homolog.prd.appnoz.com.br/',
    urlClientYouseed: 'https://admyouseed.bancosemear.com.br/'
  },
  fileUrl: {
    logo: 'https://timcoo-geral.s3.amazonaws.com/Logos/noz.png'
  },
  variables: {
    customMenuItem: true
  },
  CurrencyName: {
    name: 'Noz/Nozes'
  },
  teamName: {
    name: 'sample'
  },
  hasToken: false,
  hostHeader: ''
};
